<template>
  <main class="miningLandscape">
    <div class="miningLandscape__banner"></div>
    <section class="miningLandscape__section">
      <div class="miningLandscape__container">
        <div class="miningLandscape__cardContainer container">
          <Card
            v-for="(miningItem, index) in miningLists"
            :key="index"
            :item="miningItem"
          />
        </div>
      </div>
    </section>
    <div class="container">
      <hr class="miningLandscape__hr" />
    </div>
  </main>
</template>

<script>
// Modules
import Card from '@/components/MiningLandscape/Card.vue'

// API
import { apiGetMiningLandscape } from '@/api/webAPI'

export default {
  components: { Card },
  data() {
    return {
      miningLists: []
    }
  },
  async created() {
    const miningLandscapeResponse = await apiGetMiningLandscape()
    const miningLists = miningLandscapeResponse.data.data
    this.miningLists = miningLists
  }
}
</script>

<style lang="scss">
.miningLandscape {
  &__banner,
  &__section {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &__banner {
    background-image: url('~@/assets/image/MiningLandscape/Banner.jpg');
    padding-bottom: 12.7%;
  }

  &__section {
    background-image: url('~@/assets/image/MiningLandscape/Section.jpg');
    position: relative;
    margin-top: 5.6rem;
    padding-bottom: 38%;
  }

  &__container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__cardContainer {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    padding: 8rem;
    // transform: translateY(-60%);
    margin-top: -19rem;
  }

  &__hr {
    border: none;
    border-bottom: 1px dashed #d0a805;
    width: 100%;
    margin: 4rem 0;
  }
}

@media (max-width: 768px) {
  .miningLandscape {
    &__banner {
      background-image: url('~@/assets/image/MiningLandscape/BannerMobile.jpg');
      padding-bottom: 48.8%;
    }

    &__section {
      background-image: url('~@/assets/image/MiningLandscape/SectionMobile.jpg');
      margin-top: 5.6rem;
      position: relative;
      padding-bottom: 360%;
    }

    &__cardContainer {
      padding: 0;
      transform: translateY(0.5%);
    }
  }
}

@media (max-width: 605px) {
  .miningLandscape {
    &__cardContainer {
      transform: translateY(3.5%);
    }
  }
}

@media (max-width: 440px) {
  .miningLandscape {
    &__cardContainer {
      transform: translateY(5%);
    }
  }
}
</style>
