<template>
  <div class="miningLandscapeCard">
    <a
      :title="`${item.title}(另開新視窗)`"
      :href="item.link"
      target="_blank"
      rel="noreferrer noopener"
      class="miningLandscapeCard__main"
    >
      <div
        class="miningLandscapeCard__img"
        :style="`background-image: url('${item.image}')`"
      >
        <img
          class="miningLandscapeCard__360"
          src="@/assets/image/MiningLandscape/360.png"
          :alt="`${item.title}`"
        />
        <p class="miningLandscapeCard__text">{{ item.desc }}</p>
      </div>
      <h2 class="miningLandscapeCard__title">
        <span class="miningLandscapeCard__titleIcon"></span>
        {{ item.title }}
      </h2>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({
        title: '',
        image: '',
        desc: '',
        link: ''
      })
    }
  }
}
</script>

<style lang="scss">
.miningLandscapeCard {
  width: 33.333%;
  padding: 1.2rem;

  &__main {
    display: block;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.08);
  }
  &__img {
    padding-bottom: 112%;
    // padding-bottom: 150%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
  }

  &__360 {
    width: 11.9rem;
    height: 3.9rem;
    position: absolute;
    object-fit: contain;
    z-index: 1;
  }

  &__titleIcon {
    background-image: url('~@/assets/image/TitleIcon.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 2.2rem;
    height: 1.5rem;
    display: inline-block;
    margin-right: 0.8rem;
  }

  &__title {
    position: relative;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.3rem;
    background-color: white;
    padding: 1.9rem 2.1rem;
  }

  &__text {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(35, 24, 21, 0.8);
    color: white;
    padding: 5rem 2rem;
    padding-top: 8rem;
    font-weight: 500;
    font-size: min(1.2vw, 1.6rem);
    line-height: 140%;
    text-overflow: ellipsis;
    overflow: hidden;
    opacity: 0;
    transition: all 0.3s linear;
  }

  &__main:hover &__text {
    opacity: 1;
  }
}

@media (max-width: 1000px) {
  .miningLandscapeCard {
    &__text {
      padding: 5rem 1rem;
    }
  }
}

@media (max-width: 768px) {
  .miningLandscapeCard {
    width: 100%;
    padding: 1.6rem;

    &__text {
      font-size: 1.6rem;
      padding: 8rem 2rem;
      opacity: 1;
    }
  }
}
</style>
